/* Animated underline styles */
.nav-link {
  position: relative;
  text-decoration: none;
  padding-bottom: 2px;
}

.nav-link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1.5px;
  background-color: currentColor;
  transition: width 0.4s cubic-bezier(0.45, 0, 0.55, 1) 0.1s;
  transform-origin: left;
}

.nav-link:hover::after {
  width: 100%;
}
