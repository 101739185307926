@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Add a pulsing reflection effect */
@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.7;
  }
}

/* Inner ring effect */
.dvd-logo::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 45px;
  height: 45px;
  background: radial-gradient(circle at center, #ffffff 0%, #f0f0f0 70%, #e0e0e0 100%);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 
    inset 0 2px 6px rgba(255,255,255,0.8),
    inset 0 -2px 6px rgba(0,0,0,0.1),
    0 0 10px rgba(255,255,255,0.2);
}

/* Center hole effect */
.dvd-logo::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 30px;
  background: radial-gradient(circle at center, #ffffff 0%, #f8f8f8 85%, #e8e8e8 100%);
  border-radius: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 
    inset 0 2px 4px rgba(0,0,0,0.1),
    inset 0 -2px 4px rgba(255,255,255,0.8),
    0 0 12px rgba(255,255,255,0.4);
}

/* Top reflection */
.dvd-logo .reflection {
  content: '';
  position: absolute;
  top: 5%;
  left: 10%;
  width: 60%;
  height: 30%;
  background: linear-gradient(135deg, rgba(255,255,255,0.95) 0%, rgba(255,255,255,0) 100%);
  border-radius: 50%;
  filter: blur(2px);
  animation: pulse 2s ease-in-out infinite;
  pointer-events: none;
}
